import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import { esterPurple } from './customStyle';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';

import { useWindowSize } from 'utils/windowSizeHook';

const CodeBlock = ({ data, className }) => {
  const { content } = data;

  const width = useWindowSize();

  const highlighterRef = useRef(null);
  const [aspectRatio, setAspectRatio] = useState(false);
  const [lineNumbers, setlineNumbers] = useState(true);

  useEffect(() => {
    const codeHeight = highlighterRef.current.lastChild.clientHeight;
    const codeWidth = highlighterRef.current.lastChild.clientWidth;

    (codeHeight / codeWidth) * 100 > 56.25
      ? setAspectRatio(true)
      : setAspectRatio(false);
  }, [width]);

  useEffect(() => {
    if (width > 768) {
      setlineNumbers(true);
    } else {
      setlineNumbers(false);
    }
  }, [width]);

  return (
    <Section className={cn('code-block', [className])}>
      <Container>
        <Row>
          <Column
            xl="8"
            lg="10"
            md="10"
            className="offset-xl-2 offset-md-1 offset-lg-1"
          >
            <div className="code-block__content">
              <div
                className={cn('code-block__code-container', {
                  'code-block__code-container_aspect-ratio': aspectRatio,
                })}
                ref={highlighterRef}
              >
                <SyntaxHighlighter
                  className={cn('code-block__code', {
                    'code-block__code_aspect-ratio': aspectRatio,
                  })}
                  language="jsx"
                  style={esterPurple}
                  wrapLines={true}
                  showLineNumbers={lineNumbers}
                  wrapLongLines={true}
                  codeTagProps={{
                    style: {
                      lineHeight: 'inherit',
                      fontSize: 'inherit',
                    },
                  }}
                >
                  {content}
                </SyntaxHighlighter>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

CodeBlock.defaultProps = {
  className: '',
};

CodeBlock.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default CodeBlock;
